<template>
    <div class="main">
        <!-- <Header></Header> -->
      <div class="banner" style="width:100%; height:auto; margin-bottom: 40px; text-align: center;">
        <img :src="Src_" alt="" style="width:100%; height:100%;">
      </div>
      <div>
        <div class="product_pc">
          <el-row>
            <el-col :xs="24" :sm="12" style="height:100%;">
              <div class="product_pc_one">
                <div class="product_mobile_title font_size_30">
                  {{ manages[0].title }}
                </div>
                <div style="margin-top: 20px" class="product_font_style">
                  {{ manages[0].content[0] }}
                </div>
                <div style="margin-top: 5px" class="product_font_style">
                  {{ manages[0].content[1] }}
                </div>
                <!-- <div style="margin-top: 10px" class="product_font_style">
                  {{ manages[0].content[2] }}
                </div>
                <div style="margin-top: 10px" class="product_font_style">
                  {{ manages[0].content[3] }}
                </div>
                <div style="margin-top: 10px" class="product_font_style">
                  {{ manages[0].content[4] }}
                </div> -->
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" style="height:100%;">
              <img :src="manages[0].img[0]" class="product_pc_img" />
            </el-col>
          </el-row>
        </div>
        <div class="product_pc">
          <el-row>
            <el-col :xs="24" :sm="12" style="height:100%;">
              <img :src="manages[1].img[0]" class="product_pc_img" />
            </el-col>
            <el-col :xs="24" :sm="12" style="height:100%;">
              <div class="product_pc_one">
                <div class="product_mobile_title font_size_30">
                  {{ manages[1].title }}
                </div>
                <div style="margin-top: 20px" class="product_font_style">
                  {{ manages[1].content[0] }}
                </div>
                <div style="margin-top: 5px" class="product_font_style">
                  {{ manages[1].content[1] }}
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="product_pc">
          <el-row>
            <el-col :xs="24" :sm="12" style="height:100%;">
              <div class="product_pc_one">
                <div class="product_mobile_title font_size_30">
                  {{ manages[2].title }}
                </div>
                <div style="margin-top: 20px" class="product_font_style">
                  {{ manages[2].content[0] }}
                </div>
                <div style="margin-top: 5px" class="product_font_style">
                  {{ manages[2].content[1] }}
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" style="height:100%;">
              <img :src="manages[2].img[0]" class="product_pc_img" />
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="width: 100%; height:100px;"></div>
      <Footer></Footer>
    </div>
  </template>
  <script>
   import Header from './Layout'
  import Footer from './footer'
      export default {
    components:{Header,Footer},
          name: 'Index',
          data() {
              return {
                  manages: [
                      {
                          img: [('https://dean-upload001.oss-cn-hangzhou.aliyuncs.com/t1.png')],
                          title: "银行健康权益解决方案",
                          content: ["为私人银行、积分商城提供就医、陪诊、体检等一站式健康权益解决方案，包括三甲医院体检、私立医院体检、人工挂号陪诊、重疾住院/手术协调、家庭医生在线问诊、齿科洁牙、基因检测、细胞存储、海外医疗等服务。"
                          ]
                      },
                      {
                          img: [('https://dean-upload001.oss-cn-hangzhou.aliyuncs.com/t2.png')],
                          title: "保险健康管理解决方案",
                          content: ["为健康保险设计特色的健康保险产品，提供公立医院特需高端医疗、私立医院VIP高端体检、名医直通、专人陪诊、海外医疗等服务，为参保人提供健康风险评估、健康风险干预等服务，提供与商业健康保险产品相结合的疾病预防、健康维护、慢性病管理等健康管理服务。"
                          ]
                      },
                      {
                          img: [('https://dean-upload001.oss-cn-hangzhou.aliyuncs.com/t3.png')],
                          title: "企业员工福利解决方案",
                          content: ["为企业提供从定制化体检到全场景健康服务，从具体健康需求出发，根据员工健康状况，实际解决健康问题，提升员工满意度和幸福感，改善企业员工身体健康状况，提高员工健康意识，鼓励员工健康行为和习惯，保证员工正常工作时间，有利于提高企业工作效率，稳固人心，保障人才队伍建设，减少医药费用支出和突发意外，对企业发展十分有利。"
                          ]
                      }
                  ],
              }
          },
          methods: {},
          mounted() {
  
          },
          computed: {
            Src_() {
            let w = document.body.clientWidth;
            if (w > 600) {
                return require('../assets/jjfa.png')
            } else {
                return require('../assets/jjfa_2.png')
            }
        },
              //计算高度
              computeHeight() {
                  let screenHeight = document.body.clientWidth;
                  if (screenHeight > 600) {
                      return '550px';
                  } else {
                      return '250px';
                  }
              },
              computeArrowImg() {
                  let screenHeight = document.body.clientWidth;
                  if (screenHeight > 600) {
                      return require('../assets/other/arrow.png');
                  } else {
                      return require('../assets/other/arrow_down.png');
                  }
              }
  
          },
      }
  </script>
  <style scoped>
  .main {
    width: 100%;
  }
  .product_pc::v-deep .el-row{
    display: flex;
    align-items: center;
  }
  @media screen and (min-width: 800px) {
    .banner{
        height: auto !important;
        margin-bottom: 0 !important;
    }
}
  @media screen and (max-device-width: 600px) {
    .product_pc::v-deep .el-row{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .banner{
      height: auto !important;
    }
    .product_mobile_title{
      font-size: 20px !important;
    }
    .product_font_style{
      font-size: 14PX !important;
    }
    .product_pc_one {
      padding: 20px !important;
    }
  }
  .product_pc_one {
    height:100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 50px;
  }
  .product_pc_img {
    width: 100%;
    /* height: 100%; */
  }
  .product_mobile_title{
    font-size: 34px;
  }
  .product_font_style{
    font-size: 20px;
  }
  .product_pc_img_div {
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
  }
  
  .product_pc {
    display: flex;
    width: 90%;
    margin: 20px auto;
    background: #f2f6fc;
    align-items: center;
  }
  </style>